import { Draft } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { WALL_ART_ID } from '@/components/wallArts/constants';
import { UploaderImage, UploaderStatus } from '@/context/Uploader/types/image';
import { ItemMetadata } from '@/types/order';
import { Product, ProductSpecs } from '@/types/products';
import { Template } from '@/types/template';
import isSquareImage from '@/utils/cart/isSquareImage';
import getLocalStorageItem from '@/utils/localStorage/getLocalStorageItem';
import removeLocalStorageItem from '@/utils/localStorage/removeLocalStorageItem';
import generateProductUuid from '@/utils/order/generateProductUuid';
import isAllAssetsUploaded from '@/utils/template/isAllAssetsUploaded';

import { Cart, CartItem } from './types';

// Migration from old cart to new cart store
function retrieveOldCartItems() {
  const localCart = getLocalStorageItem<CartItem[]>('cart', []);
  const filteredCart = localCart.filter(
    (item: CartItem) =>
      (!!item && !!item.url) ||
      (!!item && item.template && isAllAssetsUploaded(item?.template)),
  );

  removeLocalStorageItem('cart');
  return filteredCart;
}

const useCartStore = create<Cart & { hasHydrated: boolean }>()(
  persist(
    // _set is needed to type zustand correctly
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    immer((_set) => ({
      items: retrieveOldCartItems(),
      squareConversionSuggestedIds: [],
      hasHydrated: false,
    })),
    {
      name: `${process.env.NEXT_PUBLIC_PARTNER}_cart_store`,
      onRehydrateStorage: () => (draft) => {
        if (draft) {
          draft.hasHydrated = true;
        }
      },
    },
  ),
);

export const getCartState = useCartStore.getState;

export const setCartItem = (
  id: string,
  updater: (state: Draft<CartItem>) => void,
) =>
  useCartStore.setState((draft) => {
    const cartItem = draft.items.find((item) => item.uuid === id);
    if (cartItem) updater(cartItem);
  });

export const duplicateCartItem = (
  cartItem: Omit<CartItem, 'uuid'>,
  insertionIndex?: number,
) =>
  useCartStore.setState((draft) => {
    const uuid = generateProductUuid(
      cartItem.productId,
      cartItem.fileId || 'no_file_id',
    );

    if (!insertionIndex) {
      draft.items.push({ ...cartItem, uuid });
    }

    if (insertionIndex) {
      draft.items.splice(insertionIndex, 0, { ...cartItem, uuid });
    }
  });

export const addTemplateItem = (templateItem: {
  productId: string;
  specs: ProductSpecs;
  template: Template;
  metadata?: ItemMetadata;
}) =>
  useCartStore.setState((draft) => {
    const newTemplateItem = {
      uuid: generateProductUuid(templateItem.productId, uuidv4()),
      productId: templateItem.productId,
      specs: templateItem.specs,
      quantity: 1,
      template: templateItem.template,
      metadata: templateItem.metadata,
      // Todo add callbacks
    };
    draft.items.push(newTemplateItem);
  });

export const addCartItem = (cartItem: {
  product: Product;
  file: {
    id: string;
    name: string;
    size: number;
  };
  metadata?: ItemMetadata;
}) => {
  const newCartItem = {
    uuid: generateProductUuid(cartItem.product.id, cartItem.file.id),
    productId: cartItem.product.id,
    specs: cartItem.product.specs,
    quantity: 1,
    metadata: cartItem.metadata,
    fileId: cartItem.file.id,
    name: cartItem.file.name,
    size: cartItem.file.size,
    isCustomCrop:
      cartItem.metadata?.productType === WALL_ART_ID ? undefined : false,
    // Todo add callbacks
  };
  useCartStore.setState((draft) => {
    draft.items.push(newCartItem);
  });

  return newCartItem;
};

export const resetCart = () =>
  useCartStore.setState((draft) => {
    draft.items = [];
  });

export const deleteCartItem = (id: string) =>
  useCartStore.setState((draft) => {
    const index = draft.items.findIndex((item) => item.uuid === id);
    if (index !== -1) draft.items.splice(index, 1);

    const squareConversionIndex = draft.squareConversionSuggestedIds.findIndex(
      (uuid) => uuid === id,
    );
    if (squareConversionIndex !== -1)
      draft.squareConversionSuggestedIds.splice(squareConversionIndex, 1);
  });

export const deleteCartItemByFileId = (fileId: string) =>
  useCartStore.setState((draft) => {
    const index = draft.items.findIndex((item) => item.fileId === fileId);
    if (index !== -1) draft.items.splice(index, 1);

    const squareConversionIndex = draft.squareConversionSuggestedIds.findIndex(
      (uuid) => uuid === draft.items[index]?.uuid,
    );
    if (squareConversionIndex !== -1)
      draft.squareConversionSuggestedIds.splice(squareConversionIndex, 1);
  });

export const setCartItemImageBlob = (
  fileId: string,
  image: UploaderImage<UploaderStatus.Prepared>,
) =>
  useCartStore.setState((draft) => {
    const itemToUpdate = draft.items.find((item) => item.fileId === fileId);

    if (itemToUpdate) {
      itemToUpdate.blobUrl = image.blobUrl;
      itemToUpdate.width = image.width;
      itemToUpdate.height = image.height;
      itemToUpdate.crop = image.crop;

      if (isSquareImage(image)) {
        draft.squareConversionSuggestedIds.push(itemToUpdate.uuid);
      }
    }
  });

export const setCartItemImageUrl = (
  fileId: string,
  image: UploaderImage<UploaderStatus.Uploaded>,
) =>
  useCartStore.setState((draft) => {
    const itemToUpdate = draft.items.find((item) => item.fileId === fileId);
    if (itemToUpdate) {
      itemToUpdate.url = image.url;
      itemToUpdate.storageKey = image.storageKey;
    }
  });

export const resetSquareConversionSuggestedIds = () =>
  useCartStore.setState((draft) => {
    draft.squareConversionSuggestedIds = [];
  });

export const removeCorruptedCartItems = () => {
  const cartItems = getCartState().items;

  const filteredCart = cartItems.filter(
    (item: CartItem) =>
      (!!item && !!item.url) ||
      (!!item && item.template && isAllAssetsUploaded(item?.template)),
  );

  const uploadingFilesRemovedCount = cartItems.length - filteredCart.length;

  useCartStore.setState((draft) => {
    if (uploadingFilesRemovedCount > 0) {
      draft.items = filteredCart;
    }
  });
  return uploadingFilesRemovedCount;
};

export default useCartStore;
