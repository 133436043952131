import { Environments, LOCAL_LOGS_KEY } from '@/constants';
import getLocalStorageItem from '@/utils/localStorage/getLocalStorageItem';
import setLocalStorageItem from '@/utils/localStorage/setLocalStorageItem';
import getSessionStorageItem from '@/utils/sessionStorage/getSessionStorageItem';
import setSessionStorageItem from '@/utils/sessionStorage/setSessionStorageItem';

import sendClientSideLogs from './client';
import {
  Log,
  SendLog,
  SendLogDebounced as SendLogDebouncedType,
} from './types';

// :warning: curently custom deviceId is send only for logs that are not GTMLog and use now=true
export const sendLog: SendLog = (event, value = {}, { now = false } = {}) => {
  if (event) {
    const log: Log = {
      timestamp: Date.now(),
      event,
      value,
    };
    let logs = getLocalStorageItem<Log[] | undefined>(
      LOCAL_LOGS_KEY,
      undefined,
    );
    if (!logs) {
      logs = [log];
    } else {
      // check if log already exist on list
      const logAlreadyOnList =
        logs.findIndex(
          (fetchedLog) => JSON.stringify(fetchedLog) === JSON.stringify(log),
        ) !== -1;
      if (!logAlreadyOnList) logs.push(log);
    }
    console.debug('LOGARRAY', logs); // eslint-disable-line no-console

    if (
      process.env.NEXT_PUBLIC_GCP_ENV === Environments.DEV ||
      process.env.NEXT_PUBLIC_GCP_ENV === Environments.INTEGRATION
    ) {
      const sessionStorageLogs =
        getSessionStorageItem<Log[] | undefined>(LOCAL_LOGS_KEY, undefined) ||
        [];
      setSessionStorageItem(
        LOCAL_LOGS_KEY,
        sessionStorageLogs
          // Limiting to 250 logs to avoid memory space error
          .slice(-250)
          .concat(log),
      );
    }

    setLocalStorageItem(LOCAL_LOGS_KEY, logs);

    if (now && logs.length > 0) {
      sendClientSideLogs(logs);
    }
  }
};

let timeoutId: ReturnType<typeof setTimeout>;
export const sendLogDebounced: SendLogDebouncedType = (
  event,
  value = {},
  { now = false, debounceDelay = 0 } = {},
) => {
  clearTimeout(timeoutId);
  // Todo: check que les logs passent bien sans la ref
  timeoutId = setTimeout(() => {
    sendLog(event, value, { now });
  }, debounceDelay);
};
