import isStorageAvailable from './storageAvailable';

const removeLocalStorageItem = (key: string): boolean => {
  if (!isStorageAvailable) return false;
  const partnerKey = `${process.env.NEXT_PUBLIC_PARTNER}_${key}`;
  localStorage.removeItem(partnerKey);
  return true;
};

export default removeLocalStorageItem;
