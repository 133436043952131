import { WallArtData } from '@/components/wallArts/types/wallArtData';

export const DEFAULT_SIZE_GENERATION = '20x30';
export const POSTER_FINAL_SIZE = 2500;

export const WALL_ART_ID = 'wallArt';

export const REMOTE_IMAGES_URL = `https://storage.googleapis.com/${process.env.NEXT_PUBLIC_GCP_PROJECT}-remoteconfig-images/calamar-web`;

export const CUSTOM_WALL_ART_ID = 'customWallArt';
export const SPOTIFY_WALL_ART_ID = 'spotifyWallArt';
export const MOVIE_WALL_ART_ID = 'netflixWallArt';
export const TEXT_WALL_ART_ID = 'textWallArt';
export const CITY_MAPS_ID = 'cityMaps';
export const WELCOME_SIGN_ID = 'welcomeSign';

const partnerDisplayName = 'Walgreens';

export interface ProductData {
  id: string;
  type: 'product';
  name?: string;
  pathName: string;
  target: string;
  projectionUrl: string;
}

export type CustomWallartData = WallArtData | ProductData;

export const isWallArtData = (data: CustomWallartData): data is WallArtData =>
  data.type === 'wallart';

export const CUSTOM_WALL_ART_LIST: CustomWallartData[] = [
  {
    type: 'wallart',
    id: SPOTIFY_WALL_ART_ID,
    name: 'Song Poster',
    pathName: 'Song',
    target: `/product/Song`,
    projectionUrl: `${REMOTE_IMAGES_URL}/wallArts/spotify.webp`,
    rectangularProjectionImages: [
      {
        id: 'projection',
        url: `${REMOTE_IMAGES_URL}/wallArts/rectangular/spotify.webp`,
        alt: 'Song poster projection',
      },
      {
        id: 'product',
        url: `${REMOTE_IMAGES_URL}/wallArts/rectangular/spotify_product.webp`,
        alt: 'song poster product',
      },
      {
        id: 'sizes',
        url: `${REMOTE_IMAGES_URL}/wallArts/rectangular/${partnerDisplayName}/productSizesPortrait.webp`,
        alt: 'Text art poster product',
      },
    ],
    shortDescription: 'Your life’s soundtrack',
    description:
      "We all have a favorite song we can't stop listening to. Say it with this poster; use the default album cover or your own photo.",
    benefitsIds: ['perfectPhoto', 'favoriteSong', '1hourPickup'],
  },
  // TODO: Uncomment when sizes and ratio issue will be fixed
  // {
  //   id: MOVIE_WALL_ART_ID,
  //   name: 'Movie Poster',
  //   pathName: 'Movie',
  //   target: '/product/Movie',
  //   projectionUrl: `${REMOTE_IMAGES_URL}/wallArts/netflix.webp`,
  //   rectangularProjectionImages: [
  //     {
  //       id: 'projection',
  //       url: `${REMOTE_IMAGES_URL}/wallArts/rectangular/netflix.webp`,
  //       alt: 'Movie poster projection',
  //     },
  //     {
  //       id: 'product',
  //       url: `${REMOTE_IMAGES_URL}/wallArts/rectangular/netflix_product.webp`,
  //       alt: 'Movie poster product',
  //     },
  //     {
  //       id: 'sizes',
  //       url: `${REMOTE_IMAGES_URL}/wallArts/rectangular/${partnerDisplayName}/productSizesLandscape.webp`,
  //       alt: 'Text art poster product',
  //     },
  //   ],
  //   shortDescription: 'Your life on the big screen',
  //   description:
  //     'Get a personalized movie poster today to add a touch of Hollywood glamour to your home or office. Showcase your favorite film or make your life feel like a movie!',
  //   benefitsIds: ['perfectPhoto', 'customizeTitleDescription', '1hourPickup'],
  // },
  {
    type: 'wallart',
    id: TEXT_WALL_ART_ID,
    name: 'Text Art',
    pathName: 'TextArt',
    target: '/product/TextArt',
    projectionUrl: `${REMOTE_IMAGES_URL}/wallArts/textArtPoster.webp`,
    rectangularProjectionImages: [
      {
        id: 'projection',
        url: `${REMOTE_IMAGES_URL}/wallArts/rectangular/textArtPoster.webp`,
        alt: 'Text art poster projection',
      },
      {
        id: 'product',
        url: `${REMOTE_IMAGES_URL}/wallArts/rectangular/textArtPoster_product.webp`,
        alt: 'Text art poster product',
      },
      {
        id: 'sizes',
        url: `${REMOTE_IMAGES_URL}/wallArts/rectangular/${partnerDisplayName}/productSizesPortrait.webp`,
        alt: 'Text art poster product',
      },
    ],
    shortDescription: 'Capture the moment with words',
    description:
      'The poster features an impressive display of text art, showcasing a unique blend of typography and design. The text art creates an intricate pattern that draws the eye in and demands attention.',
    benefitsIds: ['perfectPhoto', 'customizeText', '1hourPickup'],
  },
  // TODO: Uncomment when autocomplete route (not only usa) will be available from backend api
  // {
  //   type: 'wallart',
  //   id: CITY_MAPS_ID,
  //   name: 'City Map',
  //   pathName: 'CityMap',
  //   target: '/product/CityMap',
  //   projectionUrl: `${REMOTE_IMAGES_URL}/wallArts/city_maps_poster_24x36.webp`,
  //   rectangularProjectionImages: [
  //     {
  //       id: 'projection',
  //       url: `${REMOTE_IMAGES_URL}/wallArts/rectangular/city_maps_poster.webp`,
  //       alt: 'City map poster projection',
  //     },
  //     {
  //       id: 'product',
  //       url: `${REMOTE_IMAGES_URL}/wallArts/rectangular/city_maps_poster_product.webp`,
  //       alt: 'City map poster product',
  //     },
  //     {
  //       id: 'sizes',
  //       url: `${REMOTE_IMAGES_URL}/wallArts/rectangular/${partnerDisplayName}/productSizesPortrait.webp`,
  //       alt: 'Text art poster product',
  //     },
  //   ],
  //   shortDescription: 'Any city in the world on your walls.',
  //   description:
  //     'A beautiful poster to hang on your wall to remember your favorite city or place of birth. All cities worldwide available.',
  //   benefitsIds: ['selectCity', 'editTheme', '1hourPickup'],
  // },
];

CUSTOM_WALL_ART_LIST.unshift({
  type: 'product',
  id: WELCOME_SIGN_ID,
  name: 'Welcome Sign',
  pathName: 'WelcomeSign',
  target: `/product/posters?design=welcome-sign`,
  projectionUrl: `${REMOTE_IMAGES_URL}/wallArts/welcome_sign.webp`,
});

export const MAPS_DEFAULT_LOCATION_FALLBACK = {
  type: 'Feature',
  geometry: { type: 'Point', coordinates: [-73.9708, 40.68295] },
  properties: {
    id: '85977539',
    gid: 'whosonfirst:locality:85977539',
    layer: 'locality',
    source: 'whosonfirst',
    source_id: '85977539',
    name: 'New York',
    accuracy: 'centroid',
    country: 'United States',
    country_gid: 'whosonfirst:country:85633793',
    country_a: 'USA',
    region: 'New York',
    region_gid: 'whosonfirst:region:85688543',
    region_a: 'NY',
    county: 'Richmond County',
    county_gid: 'whosonfirst:county:102081779',
    locality: 'New York',
    locality_gid: 'whosonfirst:locality:85977539',
    continent: 'North America',
    continent_gid: 'whosonfirst:continent:102191575',
    label: 'New York, NY, USA',
  },
  bbox: [-74.255591, 40.496134, -73.700009, 40.915533],
  title: '',
  slogan: '',
  subtitle: '',
};

export const MAPS_DEFAULT_LOCATION = 'New York, USA';
