import getBasePath from '@/utils/getBasePath';
import { ResponseError } from '../types';

export default async function fetchInternalApi<TData>(
  url: string,
  requestInit?: RequestInit,
): Promise<TData | ResponseError> {
  const res = await fetch(`${getBasePath()}/api/${url}`, requestInit);

  const data = await res.json();

  return data;
}
